
/**=====================
     76. Language CSS start
==========================**/
.language {
  position: relative;
}
.js-languageSelect{
  position: absolute;
  top: 18px;
  right: 30px;
  padding: 12px;
}
/**=====================
     76. Language CSS end
==========================**/