.form-group input:focus {
  outline: none;
}
.formik-field_wrap {
  display: inline-block !important ;
  width: 100% !important;
  margin-top: 10px !important;
}
.formik-field-left {
  width: 40% !important;
  float: left !important;
}
.formik-field-right {
  width: 40% !important;
  float: right !important;
}
.formik-field_wrap input {
  border-radius: 5px;
}
.ant-table-cell.hide {
  display: none;
}
.ant-table-cell.show {
  display: block;
}
.ant-table.ant-table-small thead > tr > th {
  font-weight: bold;
}
.header-logo {
  /* width: 190px;
  height: 50px; */
  width: 30%;
  margin: 0 auto;
  margin-top: 10px;
  display: block !important;
}
html.color-6 .page-wrapper .page-body-wrapper .page-header .row h3 {
  text-transform: none;
}
/* html.color-6 .customizer-contain > .nav,
html.color-6 .customizer-contain > .nav > .nav-tabs,
html.color-6 .customizer-links > .nav,
html.color-6 .customizer-links > .nav > .nav-tabs {
  display: none;
} */
.disable-setting {
  display: none;
}
.feather-svg svg {
  width: 18px;
}

.DraftEditor-editorContainer {
  height: 300px;
  min-height: 300px;
}
